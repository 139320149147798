exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adult-js": () => import("./../../../src/pages/adult.js" /* webpackChunkName: "component---src-pages-adult-js" */),
  "component---src-pages-autumn-js": () => import("./../../../src/pages/autumn.js" /* webpackChunkName: "component---src-pages-autumn-js" */),
  "component---src-pages-bolgaria-js": () => import("./../../../src/pages/bolgaria.js" /* webpackChunkName: "component---src-pages-bolgaria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-london-summer-2024-js": () => import("./../../../src/pages/london_summer_2024.js" /* webpackChunkName: "component---src-pages-london-summer-2024-js" */),
  "component---src-pages-london-summer-2025-js": () => import("./../../../src/pages/london_summer_2025.js" /* webpackChunkName: "component---src-pages-london-summer-2025-js" */),
  "component---src-pages-london-weekend-js": () => import("./../../../src/pages/london-weekend.js" /* webpackChunkName: "component---src-pages-london-weekend-js" */),
  "component---src-pages-plai-js": () => import("./../../../src/pages/plai.js" /* webpackChunkName: "component---src-pages-plai-js" */),
  "component---src-pages-play-2024-js": () => import("./../../../src/pages/play2024.js" /* webpackChunkName: "component---src-pages-play-2024-js" */),
  "component---src-pages-podiaka-js": () => import("./../../../src/pages/podiaka.js" /* webpackChunkName: "component---src-pages-podiaka-js" */),
  "component---src-pages-preschool-js": () => import("./../../../src/pages/preschool.js" /* webpackChunkName: "component---src-pages-preschool-js" */),
  "component---src-pages-slovachunna-js": () => import("./../../../src/pages/slovachunna.js" /* webpackChunkName: "component---src-pages-slovachunna-js" */),
  "component---src-pages-winter-fairy-tales-lviv-js": () => import("./../../../src/pages/winter-fairy-tales-lviv.js" /* webpackChunkName: "component---src-pages-winter-fairy-tales-lviv-js" */),
  "component---src-pages-winter-fairy-tales-yaremche-js": () => import("./../../../src/pages/winter-fairy-tales-yaremche.js" /* webpackChunkName: "component---src-pages-winter-fairy-tales-yaremche-js" */),
  "component---src-pages-yaremche-2024-js": () => import("./../../../src/pages/yaremche2024.js" /* webpackChunkName: "component---src-pages-yaremche-2024-js" */),
  "component---src-pages-yaremche-2025-js": () => import("./../../../src/pages/yaremche2025.js" /* webpackChunkName: "component---src-pages-yaremche-2025-js" */),
  "component---src-pages-yaremche-js": () => import("./../../../src/pages/yaremche.js" /* webpackChunkName: "component---src-pages-yaremche-js" */)
}

